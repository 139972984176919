import React from 'react';
import { graphql } from 'gatsby';

import Page, { PageType } from '@/components/Page';
import Content, {
  ContentBlocksProps,
  createContentItems,
} from '@/components/Content';
import BlockWrapper from '@/components/BlockWrapper';
import { Title } from '@keytrade/components-title';
import Container from '@/components/Container';
type Props = ContentBlocksProps & PageType;

const ContactPageTemplate: React.FC<Props> = (props) => {
  const { title, content } = props.data.contentfulPage.pageType;
  const contentItems = createContentItems(content, props);
  const backgroundColor: string = props.data.contentfulPage.headerBackground;

  return (
    <Page {...props}>
      <BlockWrapper color='Light blue'>
        <Container narrow>
          <Title size='xxxl' level='h1' center margin='50px 0 70px'>
            {title}
          </Title>
        </Container>
      </BlockWrapper>
      <Content
        pageColor={backgroundColor}
        items={contentItems}
        locale={props.pageContext.locale}
      />
    </Page>
  );
};

export default ContactPageTemplate;

export const pageQuery = graphql`
  query ContactPageQuery(
    $contentfulId: String!
    $locale: String
    $articles: [String]
  ) {
    contentfulPage(
      contentful_id: { eq: $contentfulId }
      node_locale: { eq: $locale }
    ) {
      node_locale
      seoMetadata {
        ...SeoMetaDataFragment
      }
      headerBackground
      pageType {
        ... on ContentfulContactPageType {
          title
          content {
            ...ContentFragment
          }
        }
      }
    }
    allLanguages: allContentfulPage(
      filter: { contentful_id: { eq: $contentfulId } }
    ) {
      nodes {
        node_locale
        ...PagePathFragment
      }
    }
    contentfulNavigation(node_locale: { eq: $locale }) {
      ...NavigationFragment
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      ...FooterFragment
    }
    relatedArticles: allContentfulArticle(
      filter: { contentful_id: { in: $articles }, node_locale: { eq: $locale } }
    ) {
      nodes {
        ...ArticleBlockFragment
      }
    }
    contentfulBlogPageType(node_locale: { eq: $locale }) {
      page {
        node_locale
        ...PagePathFragment
      }
    }
    contentfulSupportHomepageType(node_locale: { eq: $locale }) {
      page {
        node_locale
        ...PagePathFragment
      }
    }
    contentfulLoginDialog(node_locale: { eq: $locale }) {
      ...LoginFragment
    }
  }
`;
